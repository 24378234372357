import Glide from '@glidejs/glide';

if (document.querySelectorAll('#js-glide').length > 0) {
  new Glide('#js-glide', {
    type: 'carousel',
    startAt: 0,
    perView: 1,
    gap: 0,
    breakpoints: {
      986: {
        perView: 2,
        gap: 10
      },
      560: {
        perView: 1
      }
    }
  }).mount();
}

if (document.querySelectorAll('#js-glide-company').length > 0) {
  new Glide('#js-glide-company', {
    type: 'slider',
    startAt: 0,
    perView: 4,
    gap: 0,
    breakpoints: {
      986: {
        perView: 3
      },
      560: {
        perView: 2
      }
    }
  }).mount();
}
