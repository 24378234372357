// file.ts
//
// ファイルアップロードの表示をカスタマイズしています。
// File APIを使っているのでIE10未満だと動作しません。
//
// ```
// .c-file
//   label for="#{name}"
//     .c-button ファイルを選択
//     input type="file" id="#{name}"
//   small#js-#{name} 選択してください
// ```

const form = document.querySelectorAll('form');
const isFileInput = (e: Element): e is HTMLInputElement => e && e.tagName == 'INPUT';
if (form.length > 0) {
  // JSで追加された要素でも適用されるようにイベントバブリングで上の要素でキャッチする
  document.addEventListener('change', (e) => {
    const input = e.target as Element;
    if (isFileInput(input) && input.getAttribute('type') === 'file' && window.File) {
      const id = `js-${input.id}`;
      const file = (input.files || [])[0];
      const nameElem = document.getElementById(id);
      if (nameElem && file) {
        nameElem.innerHTML = file.name;
      }
    }
    return true;
  });
}
