if (document.querySelectorAll('.l-confirm-action').length > 0) {
  window.addEventListener(
    'scroll',
    function () {
      const body = document.querySelector('body');
      const confirmAction = document.querySelector('.l-confirm-action');
      const confirmContainer = document.querySelector('.l-confirm') as HTMLElement;
      if (!body || !confirmAction || !confirmContainer) {
        return;
      }
      const confirm_container_padding = Number(confirmAction?.clientHeight) + 13 + 'px';
      if (window.pageYOffset > 260) {
        confirmAction.classList.add('is-active');
        body.classList.add('is-removed-footer');
        confirmContainer.style.setProperty('padding-bottom', confirm_container_padding);
      } else {
        confirmAction.classList.remove('is-active');
        body.classList.remove('is-removed-footer');
        confirmContainer.removeAttribute('style');
      }
    },
    false
  );
}
