import Rails from '@rails/ujs';
import 'src/js/customer/index';
import 'src/js/common/toastify';
import 'fonts/LearningCurve.woff';
import 'fonts/LearningCurve.woff2';
import 'src/style/application';

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.

require.context('../images', true);

function init() {
  Rails.start();
}

if (document.readyState === 'complete') {
  init();
} else {
  document.addEventListener('DOMContentLoaded', init);
}
