import { CartStorage } from '../common/cart_storage';

const completed = document.querySelector('.l-complete-wrapper');
const additionalCompleted = document.querySelector('.l-additional-complete-wrapper');
if (completed) {
  if (completed.classList.contains('completed')) {
    const cart = new CartStorage();
    cart.clear();
  }
}
if (additionalCompleted) {
  const cart = new CartStorage();
  cart.cartKey = CartStorage.ADDITIONAL_OPTION_CART_KEY;
  cart.clear();
}
