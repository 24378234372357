import flatpickr from 'flatpickr';
import { Japanese } from 'flatpickr/dist/l10n/ja.js';

let fp = flatpickr('.js-datepicker--delivery', {
  locale: Japanese,
  enableTime: false,
  dateFormat: 'Y-m-d',
  onChange: function () {
    const delivery_htmls = document.querySelectorAll('.l-delivery__result');
    delivery_htmls.forEach(function (html) {
      html.style.display = 'none';
    });
  }
});

const btn = document.querySelector('.l-delivery .c-button');
if (btn !== null) {
  btn.addEventListener('click', function () {
    const delivery_htmls = document.querySelectorAll('.l-delivery__result');
    delivery_htmls.forEach(function (html) {
      html.style.display = 'none';
    });
    const delivery_date = fp.selectedDates[0];
    const delivery_date_str =
      delivery_date.getFullYear() +
      '年' +
      (delivery_date.getMonth() + 1) +
      '月' +
      delivery_date.getDate() +
      '日お届けの場合';
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const interval_days = (delivery_date.getTime() - today.getTime()) / (1000 * 60 * 60 * 24);
    const limit_date = new Date(delivery_date.getFullYear(), delivery_date.getMonth(), delivery_date.getDate() - 30);
    const limit_date_str =
      limit_date.getFullYear() + '年' + (limit_date.getMonth() + 1) + '月' + limit_date.getDate() + '日';
    if (interval_days >= 30) {
      delivery_htmls[0].style.display = 'block';
      let word = delivery_htmls[0].querySelector('dt');
      word.innerText = delivery_date_str;
      let limit = delivery_htmls[0].querySelector('dd p strong');
      limit.innerText = limit_date_str;
    } else if (interval_days >= 8 && interval_days < 30) {
      delivery_htmls[1].style.display = 'block';
      let word = delivery_htmls[1].querySelector('dt');
      word.innerText = delivery_date_str;
    } else if (interval_days >= 5 && interval_days < 8) {
      delivery_htmls[2].style.display = 'block';
      let word = delivery_htmls[2].querySelector('dt');
      word.innerText = delivery_date_str;
    } else {
      delivery_htmls[3].style.display = 'block';
      let word = delivery_htmls[3].querySelector('dt');
      word.innerText = delivery_date_str;
    }
  });
}
