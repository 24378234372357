import { CartStorage } from '../common/cart_storage';

const commaFormatter = new Intl.NumberFormat('ja');
const cart = new CartStorage();
if (document.querySelector('.c-container--additional-options-create, .additional-options')) {
  cart.cartKey = CartStorage.ADDITIONAL_OPTION_CART_KEY;
}

type Prices = {
  subTotal: string;
  tax: string;
  total: string;
};

const updatePrice = (prices: Prices) => {
  const subTotal = document.querySelector('.js-subtotal-option-price');
  if (subTotal) {
    subTotal.textContent = prices.subTotal;
  }
  const tax = document.querySelector('.js-tax');

  if (tax) {
    tax.textContent = prices.tax;
  }
  const total = document.querySelector('.js-total');
  if (total) {
    total.textContent = prices.total;
  }
};

const onChangeCheck = () => {
  let price = 0;
  let checkedAnyValue = false;
  document.querySelectorAll('.option-value-radio:checked').forEach((checked) => {
    const radio = checked as HTMLInputElement;
    price += Number(radio.dataset.price);
    checkedAnyValue = true;
  });
  const taxRate = Number((document.querySelector('.additional-options') as HTMLDivElement).dataset.tax);
  updatePrice({
    subTotal: `${checkedAnyValue ? commaFormatter.format(price) : '----'}円`,
    tax: `${checkedAnyValue ? commaFormatter.format(price * taxRate) : '----'}円`,
    total: `${checkedAnyValue ? commaFormatter.format(price * (1 + taxRate)) : '----'}`
  });
  const submitButton = document.querySelector('.c-button__submit');
  if (submitButton) {
    if (checkedAnyValue) {
      submitButton.removeAttribute('disabled');
    } else {
      submitButton.setAttribute('disabled', 'disabled');
    }
  }
};

const defaultOptionSetByQuery = () => {
  const query = new window.URLSearchParams(location.search);
  const optionKeyId: string = query.get('option_key_id') || '';
  const optionValueIds: string[] = query.get('option_value_ids')?.split(',') || [];

  cart.changeOrderOption(optionValueIds);

  if (optionKeyId && optionValueIds.length > 0) {
    const keyCheckbox = <HTMLInputElement>document.getElementById(`key_${optionKeyId}`);
    keyCheckbox.checked = true;

    const option = <HTMLInputElement>document.getElementById(`option_${optionKeyId}`);
    option.classList.add('is-active');

    optionValueIds.forEach((value) => {
      const valueRadio = <HTMLInputElement>document.getElementById(`option_value_${value}`);
      valueRadio.checked = true;
    });
  }
  accordionsSet();
};

const accordionsSet = () => {
  const accordions = document.querySelectorAll('.js-accordion');
  for (let i = 0; i < accordions.length; i++) {
    const accordion = <HTMLInputElement>accordions[i];
    const isActive: boolean = accordion.classList.contains('is-active');
    const target = accordion.dataset.target;

    if (target) {
      const targetElement = document.getElementById(target);
      if (targetElement) {
        if (isActive) {
          targetElement.classList.add('is-active');
        } else {
          targetElement.classList.remove('is-active');
        }
      }
    }
  }
};

const additionalOptions = document.querySelector('.additional-options');
if (additionalOptions) {
  additionalOptions.querySelectorAll('.option-value-radio, .option-key-checkbox').forEach((input) => {
    input.addEventListener('change', onChangeCheck);
  });
  document.addEventListener('DOMContentLoaded', defaultOptionSetByQuery);
  document.addEventListener('DOMContentLoaded', onChangeCheck);
}
