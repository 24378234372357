let tab_navs = document.querySelectorAll('.p-tab__nav a');
for (let i = 0; i < tab_navs.length; i++) {
  tab_navs[i].addEventListener('click', tab_change);
}
function tab_change(click) {
  for (var i = 0; i < tab_navs.length; i++) {
    tab_navs[i].classList.remove('is-active');
  }
  let clicked = click.currentTarget;
  clicked.classList.add('is-active');
  click.preventDefault();
  let tab_content = document.querySelectorAll('.p-tab__content');
  for (i = 0; i < tab_content.length; i++) {
    tab_content[i].classList.remove('is-active');
  }
  let anchorReference = click.target;
  let active_content_id = anchorReference.getAttribute('href');
  let active_content = document.querySelector(active_content_id);
  active_content.classList.add('is-active');
}
