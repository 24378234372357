const addOptionButtons = document.querySelectorAll<HTMLButtonElement>('.add-option');
const optionsContainer = document.querySelector('.js-options');

export function addOptionListener(): void {
  addOptionButtons.forEach((addOption) => {
    addOption.addEventListener('click', function (e: Event) {
      const optionIds: string[] = [];

      const { target } = e;
      if (!(target instanceof HTMLButtonElement)) {
        return;
      }
      const targetContainer = target?.closest('.js-options');

      if (targetContainer) {
        const optionRadios = targetContainer.querySelectorAll('input.option-radio[type="radio"]');

        for (let i = 0; i < optionRadios.length; i++) {
          const radio = <HTMLInputElement>optionRadios[i];
          if (radio.checked) {
            optionIds.push(radio.value);
          }
        }
      } else {
        if (this.dataset.id) {
          optionIds.push(this.dataset.id);
        }
      }

      if (this.dataset.cartUrl) {
        let query = optionIds.length > 0 ? '&option_value_ids=' : '';
        query += optionIds.join(',');
        location.href = this.dataset.cartUrl + query;
      }
    });
  });
}

export function initializeAddOption(): void {
  addOptionButtons.forEach((addOption) => {
    addOption.disabled = !isCheckedAllOption();
  });
}

export function addAdditionalOptionsListener(): void {
  if (optionsContainer) {
    const radioGroups = optionsContainer.querySelectorAll('.js-option-radio');
    for (let i = 0; i < radioGroups.length; i++) {
      const optionRadios = radioGroups[i].querySelectorAll('input[type="radio"]');
      for (let j = 0; j < optionRadios.length; j++) {
        optionRadios[j].addEventListener('change', function () {
          addOptionButtons.forEach((addOption) => {
            addOption.disabled = !isCheckedAllOption();
          });
        });
      }
    }
  }
}

const isCheckedAllOption = (): boolean => {
  if (!optionsContainer) {
    return false;
  }

  const radioGroups = optionsContainer.querySelectorAll('.js-option-radio');
  for (let i = 0; i < radioGroups.length; i++) {
    const optionRadios = radioGroups[i].querySelectorAll('input[type="radio"]');
    let isChecked = false;
    for (let j = 0; j < optionRadios.length; j++) {
      const radio = <HTMLInputElement>optionRadios[j];
      if (radio.checked) {
        isChecked = true;
      }
    }

    if (!isChecked) {
      return false;
    }
  }

  return true;
};
