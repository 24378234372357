import { CartStorage } from '../common/cart_storage';

document.addEventListener('DOMContentLoaded', () => {
  const header = document.querySelector('.l-header');
  if (!header) {
    return;
  }
  const cartIcon = header.querySelector('.--cart-icon');
  if (!cartIcon) {
    return;
  }
  const cart = new CartStorage();
  if (cart.hasOrderOptions() || cart.hasProducts()) {
    cartIcon.classList.add('is-active');
  } else {
    cartIcon.classList.remove('is-active');
  }
});
