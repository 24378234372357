const selectProject: HTMLSelectElement = <HTMLSelectElement>document.querySelector('.select-multi-project .c-select');
const storage = window.localStorage;

if (selectProject) {
  const toggleStateWrapper = (value: string): void => {
    const wrappers = document.querySelectorAll('.l-my__project-wrapper');
    const targetWrapper = document.querySelector(`#project-wrapper-${value}`);
    if (wrappers && targetWrapper) {
      wrappers.forEach((w) => w.classList.remove('is-active'));
      targetWrapper.classList.add('is-active');
    }
  };

  const toggleAddOrderLinkWrapper = (value: string): void => {
    const wrappers = document.querySelectorAll('.js-add-order-links');
    const targetWrapper = document.querySelector(`#add-order-${value}`);
    if (wrappers && targetWrapper) {
      wrappers.forEach((w) => w.classList.remove('is-active'));
      targetWrapper.classList.add('is-active');
    }
  };

  const projectChangeHandler = (e: Event): void => {
    const value = (e.currentTarget as HTMLSelectElement).value;
    toggleStateWrapper(value);
    storage.removeItem('editing_project_id');
    storage.setItem('editing_project_id', value);
    toggleAddOrderLinkWrapper(storage.editing_project_id);
  };
  window.onload = () => {
    if (storage.editing_project_id) {
      selectProject.value = storage.editing_project_id;
      toggleStateWrapper(storage.editing_project_id);
      toggleAddOrderLinkWrapper(storage.editing_project_id);
    }
  };
  selectProject.addEventListener('change', projectChangeHandler);
}
