const addAccordionClickListener = (accordion: HTMLInputElement) => {
  accordion.addEventListener('click', (e) => {
    if (e.target) {
      const element = <HTMLElement>e.target;
      element.classList.toggle('is-active');
      const target = element.dataset.target;
      const isActive: boolean = element.classList.contains('is-active');

      if (target) {
        const targetElement = document.getElementById(target);
        if (targetElement) {
          if (isActive) {
            targetElement.classList.add('is-active');
          } else {
            targetElement.classList.remove('is-active');
            resetAllRadio(targetElement);
          }
        }
      }
    }
  });
};

const accordions = document.querySelectorAll('.js-accordion');
for (let i = 0; i < accordions.length; i++) {
  const accordion = <HTMLInputElement>accordions[i];
  addAccordionClickListener(accordion);
}

const resetAllRadio = (element: HTMLElement) => {
  const radios = element.querySelectorAll<HTMLInputElement>("input[type='radio']");
  radios.forEach((radio) => (radio.checked = false));
};
