import * as loadImage from 'blueimp-load-image';
import * as regex from './modules/draft/regex';

const triggerInputFile = (imageInput: HTMLInputElement) => (e: Event) => {
  e.preventDefault();
  const modal = imageInput.closest<HTMLDivElement>('.js-draft-modal');
  if (modal?.classList.contains('is-shown')) {
    imageInput.click();
  }
};

const onChangeImage = (draftImage: HTMLDivElement) => (e: Event) => {
  const input = e.target as HTMLInputElement;
  if (input.files && input.files[0]) {
    const file = input.files[0];
    if (file.type.indexOf('image') < 0) {
      return;
    }
    // exifのorientation情報をみないと、画像の向きがおかしくなる場合があるのでここで修正している
    loadImage(
      file,
      (canvas: HTMLCanvasElement) => {
        const imageURL = canvas.toDataURL(file.type);
        draftImage.setAttribute('style', `background: url(${imageURL}) center / cover no-repeat`);
      },
      { orientation: true, canvas: true, crop: false, maxWidth: 400 }
    );
    const cForm = input.closest('.c-form');
    if (!cForm) {
      return;
    }
    cForm.classList.remove('is-error');
    const errors = cForm.querySelector<HTMLDivElement>('.c-form__error-wrapper');
    if (!errors) {
      return;
    }
    errors.innerHTML = '';
  }
};

const insertWarningForCheckText = (
  errorWrapper: HTMLDivElement,
  value: string,
  regex: RegExp,
  message: string,
  type: 'warning' | 'error'
) => {
  const matchedKeywords = Array.from(new Set(value.match(regex)));
  const errorMessage = message.replace('${word}', matchedKeywords.join(','));
  const errorElement = document.createElement('div');
  if (type === 'warning') {
    errorElement.classList.add('c-form__warning');
  } else {
    errorElement.classList.add('c-form__error');
  }
  errorElement.innerText = errorMessage;
  errorWrapper.append(errorElement);
};

const checkText = (
  form: HTMLFormElement,
  textCForm: HTMLDivElement,
  button: HTMLButtonElement,
  eventTarget: HTMLInputElement | HTMLTextAreaElement,
  clearError: boolean
) => {
  const targetVal = String(eventTarget.value);

  // 忌み言葉、重ね言葉をサーバーからdataを通じて取得
  const tabooWords: string[] = JSON.parse(form.dataset.taboos || '[]');
  const tabooRegex = new RegExp(tabooWords.join('|'), 'g');

  // 外字をサーバーからdataを通じて取得
  const externalCharsWords: string[] = JSON.parse(form.dataset.externalChars || '[]');
  const externalCharsRegex = new RegExp(externalCharsWords.join('|'), 'g');

  const errorWrapper = textCForm.querySelector('.c-form__error-wrapper') as HTMLDivElement | null;
  if (!errorWrapper) {
    return;
  }
  if (clearError) {
    button.removeAttribute('data-confirm');
    textCForm.classList.remove('is-error');
    errorWrapper.innerHTML = '';
  }

  if (tabooRegex.test(targetVal)) {
    //忌み言葉
    insertWarningForCheckText(errorWrapper, targetVal, tabooRegex, '「${word}」は忌み言葉・重ね言葉です。', 'warning');
    button.dataset.confirm = '忌み言葉もしくは重ね言葉を含んでいます。このまま送信してよろしいでしょうか。';
  }
  if (externalCharsRegex.test(targetVal)) {
    //しねきゃぷしょんで表示できない可能性のある文字列
    insertWarningForCheckText(
      errorWrapper,
      targetVal,
      externalCharsRegex,
      '「${word}」は動画で使用するフォントで正しく表示できない可能性があります。',
      'warning'
    );
    button.dataset.confirm = '動画で使用するフォントで正しく表示できない可能性があります。よろしいでしょうか。';
  }
  // 絵文字のalert
  if (regex.emojiRegex.test(targetVal)) {
    button.setAttribute('disabled', 'disabled');
    textCForm.classList.add('is-error');
    insertWarningForCheckText(errorWrapper, targetVal, regex.emojiRegex, '絵文字は登録できません。', 'error');
  } else {
    button.removeAttribute('disabled');
  }
};

const createObjectURL = (file: File) => {
  const windowWebkitURL = window as Window & { webkitURL: { createObjectURL: (file: File) => string } };
  if (windowWebkitURL.webkitURL) {
    return windowWebkitURL.webkitURL.createObjectURL(file);
  } else if (window.URL && window.URL.createObjectURL) {
    return window.URL.createObjectURL(file);
  } else {
    return '';
  }
};

const onChangeMusic = (e) => {
  const input = e.target as HTMLInputElement;
  const suitableMusicLength = Number(input.dataset.suitableMusicLength);
  const warningMessage = input.dataset.warningMessage;
  const file: File = e.target.files[0];
  if (!file || !warningMessage) {
    return;
  }
  const cForm = input.closest('.c-form') as HTMLDivElement;
  const errorWrapper = cForm.querySelector('.c-form__error-wrapper') as HTMLDivElement;

  errorWrapper.innerHTML = '';
  const audio: HTMLAudioElement = new Audio(createObjectURL(file));
  audio.addEventListener('loadedmetadata', () => {
    // 長さチェック
    if (audio.duration < suitableMusicLength) {
      const errorElement = document.createElement('div');
      errorElement.classList.add('c-form__warning');
      errorElement.innerText = warningMessage;
      errorWrapper.appendChild(errorElement);
      alert(warningMessage);
    }
  });
};

// 楽曲入力のときに、提出方法によってフォームを変更する
type MusicType = 'sample' | 'select' | 'none' | 'buy';
const changeMusicForms = (tabContentWrapper: HTMLDivElement) => () => {
  const musicKindSelect = tabContentWrapper.querySelector('.c-select__music_type') as HTMLSelectElement | null;
  const musicFileInputBlock = tabContentWrapper.querySelector('.c-form__block') as HTMLInputElement | null;
  const musicInfoInputs = tabContentWrapper.querySelectorAll('.buy-music-info') as NodeListOf<HTMLDivElement>;

  if (!musicKindSelect || !musicFileInputBlock || musicInfoInputs.length == 0) {
    return;
  }

  const musicType = musicKindSelect.value as MusicType;
  switch (musicType) {
    case 'select':
      musicFileInputBlock.setAttribute('style', 'display: block;');
      musicInfoInputs.forEach((input) => {
        (input.querySelector('textarea') as HTMLTextAreaElement).value = '';
        input.setAttribute('style', 'display: none;');
      });
      break;
    case 'buy':
      musicFileInputBlock.setAttribute('style', 'display: none;');
      (musicFileInputBlock.querySelector('input') as HTMLInputElement).value = '';
      musicInfoInputs.forEach((input) => {
        input.setAttribute('style', 'display: flex;');
      });
      break;
    default:
      musicFileInputBlock.setAttribute('style', 'display: none;');
      (musicFileInputBlock.querySelector('input') as HTMLInputElement).value = '';
      musicInfoInputs.forEach((input) => {
        (input.querySelector('textarea') as HTMLTextAreaElement).value = '';
        input.setAttribute('style', 'display: none;');
      });
      break;
  }
};

document.querySelectorAll('.js-draft-modal').forEach((m) => {
  const form = m.querySelector('form') as HTMLFormElement;
  const submitButton = m.querySelector('.c-button.--primary') as HTMLButtonElement;
  m.querySelectorAll('.c-form').forEach((f) => {
    const cForm = f as HTMLDivElement;
    const draftImage = (f.querySelector('.p-draft-image') as HTMLDivElement) || null;
    const imageInput = (f.querySelector('.photo-input-field') as HTMLInputElement) || null;
    if (draftImage && imageInput) {
      draftImage.addEventListener('click', triggerInputFile(imageInput));
      imageInput.addEventListener('change', onChangeImage(draftImage));
    }
    const textInput = f.querySelector<HTMLInputElement | HTMLTextAreaElement>('input[type="text"], textarea');
    if (textInput) {
      checkText(form, cForm, submitButton, textInput, false);
      textInput.addEventListener('keyup', (e) =>
        checkText(form, cForm, submitButton, e.target as HTMLInputElement | HTMLTextAreaElement, true)
      );
    }
  });
});

// 文字数カウント、指定文字以上だと赤文字
const checkValidTextLength = (textWrapper: HTMLDivElement) => {
  const textArea = textWrapper.querySelector('textarea');
  const currentTextLengthSelecter = textWrapper.querySelector('.current-text-count') as HTMLDivElement;
  const maxTextLengthSelecter = textWrapper.querySelector('.max-text-count') as HTMLDivElement;
  const maxLength = Number(maxTextLengthSelecter.dataset.maxLength);
  const currentLength: number = textArea?.value?.length || 0;

  currentTextLengthSelecter.innerText = String(currentLength);

  if (maxLength < currentLength) {
    currentTextLengthSelecter.classList.add('u-color-red-9');
    currentTextLengthSelecter.classList.remove('u-color-teal-8');
  } else {
    currentTextLengthSelecter.classList.remove('u-color-red-9');
    currentTextLengthSelecter.classList.add('u-color-teal-8');
  }
};

// Placeholderの例文を入力させる
const inputSampleText = (textArea: HTMLInputElement | HTMLTextAreaElement) => {
  const regex = /例\)|例）/;
  textArea.value = textArea.placeholder.replace(regex, '');
};

document.querySelectorAll('.js-text-item').forEach((t) => {
  const textWrapper = t as HTMLDivElement;
  const textInput = textWrapper.querySelector<HTMLInputElement | HTMLTextAreaElement>('input[type="text"], textarea');

  if (textInput) {
    checkValidTextLength(textWrapper);
    textInput.addEventListener('keyup', () => checkValidTextLength(textWrapper));

    const sampleInput = textWrapper.querySelector('[name=sample_text]');
    if (sampleInput) {
      sampleInput.addEventListener('change', (e) => {
        const target = e.target as HTMLInputElement;
        if (target.checked) {
          inputSampleText(textInput);
          checkValidTextLength(textWrapper);
        }
      });
    }
  }
});

document.querySelectorAll('.p-tab__music fieldset').forEach((t) => {
  const musicKindSelect = t.querySelector('.c-select__music_type') as HTMLSelectElement | null;
  const musicFileInput = t.querySelector('.c-form__block input[type="file"]') as HTMLInputElement | null;

  if (musicKindSelect && musicFileInput) {
    changeMusicForms(t as HTMLDivElement)();
    musicKindSelect.addEventListener('change', changeMusicForms(t as HTMLDivElement));
    musicFileInput.addEventListener('change', onChangeMusic);
  }
});
