type Device = 'PC' | 'SP';
interface Window {
  device: Device;
}

if (
  navigator.userAgent.indexOf('iPhone') > 0 ||
  (navigator.userAgent.indexOf('Android') > 0 && navigator.userAgent.indexOf('Mobile') > 0) ||
  navigator.userAgent.indexOf('iPad') > 0 ||
  navigator.userAgent.indexOf('Android') > 0
) {
  window.device = 'SP';
} else {
  window.device = 'PC';
}
