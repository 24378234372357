document.querySelectorAll('.p-nps .p-nps__bullet').forEach(function (item) {
  item.addEventListener('click', function (event) {
    event.preventDefault();
    const element = document.querySelectorAll('.p-nps__bullet');
    const els = Array.prototype.slice.call(element, 0);
    const index = els.indexOf(event.currentTarget);

    for (var i = 0; i < 10; i++) {
      if (i <= index) {
        element[i].classList.add('is-active');
      } else {
        element[i].classList.remove('is-active');
      }
    }
    document.getElementById('enquete_recommend').value = index + 1;
  });
});
