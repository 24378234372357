document.addEventListener('DOMContentLoaded', function () {
  const quality = document.querySelector('#enquete_quality');
  const respond = document.querySelector('#enquete_respond');
  if (quality !== null) {
    quality.addEventListener('keyup', calcNumberOfText);
  }
  if (respond !== null) {
    respond.addEventListener('keyup', calcNumberOfText);
  }

  if (document.querySelectorAll('.c-form__error').length > 0) {
    calcNumberOfText();
  }
  function isWindow(obj) {
    return !!obj && obj === obj.window;
  }
  function calcNumberOfText() {
    if (isWindow(this)) {
      setTextNumber(quality);
      setTextNumber(respond);
    } else {
      setTextNumber(this);
    }
  }
  function setTextNumber(elem) {
    const text_length = elem.value.replace(/\r?\n/g, '').length;
    elem.nextElementSibling.querySelector('span').textContent = ' ' + text_length + '/120';
    if (text_length < 120) {
      elem.nextElementSibling.classList.remove('u-color-success');
      elem.nextElementSibling.classList.add('u-color-danger');
    } else {
      elem.nextElementSibling.classList.remove('u-color-danger');
      elem.nextElementSibling.classList.add('u-color-success');
    }
  }
});
