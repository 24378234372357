// Modalの表示制御
//
// Modalを開くトリガーとなるelementに js-modal-openというクラスがついていることを前提としている
// open時にはmodalOpenイベントを発行する
// Modalを閉じるトリガーとなるelementに js-modal-closeというクラスがついていることを前提としている
// close時にはmodalCloseイベントを発行する
//
const modals = document.querySelectorAll('.js-modal--open');
for (let i = 0; i < modals.length; i++) {
  modals[i].addEventListener('click', (e) => {
    e.preventDefault();
    const offsetY = window.pageYOffset * -1 + 'px';
    const body = document.querySelector('body');
    body?.style.setProperty('top', offsetY);
    body?.classList.add('is-fixed');

    if (e.target) {
      const target = <HTMLElement>e.target;
      if (target.dataset.target) {
        const modal = document.getElementById(target.dataset.target);
        if (!modal) {
          return;
        }
        // モーダルをひらいている間は操作できないようにする
        modal.querySelectorAll<HTMLInputElement | HTMLTextAreaElement>('input, textarea').forEach((input) => {
          input.disabled = true;
        });
        modal.classList.add('is-active');
        // CSSアニメーションが0.5秒なので、ちょっと短めにdisableをはずす
        // CSSでやろうとしたが、iPhoneで動かなかったため、JSでロックしている #1077
        setTimeout(() => {
          // モーダルをひらいている間は操作できないようにする
          modal.querySelectorAll<HTMLInputElement | HTMLTextAreaElement>('input, textarea').forEach((input) => {
            input.disabled = false;
          });
          modal.classList.add('is-shown');
        }, 400);
        modal.dispatchEvent(new Event('modalOpen'));
      }
    }
  });
}

const modalCloses = document.querySelectorAll('.js-modal--close');
for (let i = 0; i < modalCloses.length; i++) {
  modalCloses[i].addEventListener('click', (e) => {
    e.preventDefault();
    const body = document.querySelector('body');
    if (body) {
      const offsetY = window.getComputedStyle(body).top.replace(/[^0-9]/g, '');
      if (e.target) {
        const target = <HTMLElement>e.target;
        const modal = target.closest('.js-modal');
        if (!modal) {
          return;
        }
        modal.classList.remove('is-active');
        modal.classList.remove('is-shown');
        modal.dispatchEvent(new Event('modalClose'));
      }
      document.querySelector('body')?.classList.remove('is-fixed');
      window.scrollTo(0, parseInt(offsetY, 10));
    }
  });
}

window.addEventListener('resize', () => {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
});

const setMovieIframe = (iframe, movieSite, movieId) => {
  switch (movieSite) {
    case 'vimeo':
      iframe.src = `https://player.vimeo.com/video/${movieId}?autoplay=1&title=0&byline=0&portrait=0`;
      break;
    case 'youtube':
      iframe.src = `https://www.youtube.com/embed/${movieId}?autoplay=1&rel=0&modestbranding=1&t=0s`;
      break;
  }
  iframe.setAttribute('style', 'position:absolute;top:0;left:0;width:100%;height:100%;');
  iframe.setAttribute('frameborder', '0');
  iframe.setAttribute('allow', 'autoplay; fullscreen');
  iframe.setAttribute('allowfullscreen', '');
};

// ムービー再生用のmodalについて、自動再生する
// 開いたときに自動再生されるように、開いたときにiframeの追加をしている
const movieModals = document.querySelectorAll('.js-movie-modal');
movieModals.forEach((movieModal) => {
  const isDivElement = (m: Element | null): m is HTMLDivElement => m !== null && m !== undefined;
  if (isDivElement(movieModal)) {
    const movieWrapper = movieModal.querySelector('.p-modal__movie');
    if (isDivElement(movieWrapper)) {
      movieModal.addEventListener('modalOpen', () => {
        const iframe = document.createElement('iframe');
        const movieSite = movieModal.dataset.movieSite;
        const movieId = movieModal.dataset.movie;
        setMovieIframe(iframe, movieSite, movieId);
        movieWrapper.innerHTML = '';
        movieWrapper.appendChild(iframe);
      });
      movieModal.addEventListener('modalClose', () => {
        movieWrapper.innerHTML = '';
      });
    }
  }
});
