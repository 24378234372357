const orderSelectProject = document.querySelector('.c-container__my-order .c-select');

if (orderSelectProject) {
  const projectChangeHandler = (e: Event): void => {
    const value = (e.currentTarget as HTMLSelectElement).value;
    const wrappers = document.querySelectorAll('.l-editor__my-order');
    const targetWrapper = document.querySelector(`.l-editor__my-order#order_${value}`);
    if (wrappers && targetWrapper) {
      wrappers.forEach((w) => w.classList.remove('is-active'));
      targetWrapper.classList.add('is-active');
    }
  };
  orderSelectProject.addEventListener('change', projectChangeHandler);
}
