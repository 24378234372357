const changeDisplayCardInfo = () => {
  const orderPayment: HTMLSelectElement | null = <HTMLSelectElement>document.getElementById('order_payment');
  const cardInfo: HTMLElement | null = document.querySelector('.js-card-info');
  if (!orderPayment || !cardInfo) {
    return;
  }

  if (orderPayment.selectedOptions[0].value === 'credit_card') {
    cardInfo.style.display = 'block';
  } else {
    cardInfo.style.display = 'none';
  }
};

document.addEventListener('DOMContentLoaded', () => {
  changeDisplayCardInfo();

  const orderPayment: HTMLSelectElement | null = <HTMLSelectElement>document.getElementById('order_payment');
  if (orderPayment) {
    orderPayment.addEventListener('change', () => {
      changeDisplayCardInfo();
    });
  }

  const payJpToken = <HTMLInputElement>document.querySelector("input[name='payjp-token']");
  const orderPayJpToken = <HTMLInputElement>document.getElementById('order_payjp_token');
  if (payJpToken && orderPayJpToken) {
    payJpToken.value = orderPayJpToken.value;
    if (payJpToken.value.length > 0) {
      const checkOutBox = <HTMLDivElement>document.getElementById('payjp_checkout_box');
      if (checkOutBox) {
        const payJpButton = <HTMLInputElement>checkOutBox.querySelector("input[type='button']");
        if (payJpButton) {
          payJpButton.classList.add('has-token');
          payJpButton.value = '✔ カード情報入力済み';
        }
      }
    }
  }

  const adjustmentWeddingDateAndTdb = function (weddingTdbElement: HTMLInputElement) {
    const checked: boolean = weddingTdbElement.checked;
    const weddingOn = <HTMLInputElement>document.getElementById('order_wedding_on');
    if (weddingOn) {
      if (checked) {
        weddingOn.value = '';
        weddingOn.classList.add('disabled');
      } else {
        weddingOn.classList.remove('disabled');
      }
    }
  };

  document.getElementById('order_wedding_date_tbd')?.addEventListener('click', (e) => {
    const weddingTbd = <HTMLInputElement>e.target;
    adjustmentWeddingDateAndTdb(weddingTbd);
  });

  const initializeAdjustment = () => {
    const weddingTbd = <HTMLInputElement>document.getElementById('order_wedding_date_tbd');
    if (weddingTbd) {
      adjustmentWeddingDateAndTdb(weddingTbd);
    }
  };

  initializeAdjustment();
});
