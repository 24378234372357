import { CartStorage } from '../common/cart_storage';
import {
  addAdditionalOptionsListener,
  addOptionListener,
  initializeAddOption
} from './modules/product/additional_option';

if (document.querySelectorAll('.l-product').length > 0) {
  const cart = new CartStorage();

  window.addEventListener(
    'scroll',
    function () {
      const body = document.querySelector('body');
      const productCounter = document.querySelector('.p-counter');
      const productContainer = document.querySelector('.l-product');
      const productCounterFooter = document.querySelector('.p-counter-footer');
      if (!body || !productCounter || !productContainer || !productCounterFooter) {
        return;
      }
      const productContainerFooterPadding = `${productCounterFooter.clientHeight}px`;
      if (window.pageYOffset > 460) {
        productCounterFooter.classList.add('is-active');
        productCounter.classList.add('is-fixed');
        body.classList.add('is-removed-footer');
        (productContainer as HTMLDivElement).style.setProperty('padding-bottom', productContainerFooterPadding);
      } else {
        productCounterFooter.classList.remove('is-active');
        productCounter.classList.remove('is-fixed');
        body.classList.remove('is-removed-footer');
        productContainer.removeAttribute('style');
      }
    },
    false
  );

  const addCartButtons = document.querySelectorAll<HTMLButtonElement>('#add-cart, #add-cart-counter');
  const optionsContainer = document.querySelector('.js-options');

  const isCheckedAllOption = (): boolean => {
    if (!optionsContainer) {
      return false;
    }

    const radioGroups = optionsContainer.querySelectorAll('.js-option-radio');
    for (let i = 0; i < radioGroups.length; i++) {
      const optionRadios = radioGroups[i].querySelectorAll('input[type="radio"]');
      let isChecked = false;
      for (let j = 0; j < optionRadios.length; j++) {
        const radio = <HTMLInputElement>optionRadios[j];
        if (radio.checked) {
          isChecked = true;
        }
      }

      if (!isChecked) {
        return false;
      }
    }

    return true;
  };

  const addOptionsListener = () => {
    if (optionsContainer) {
      const radioGroups = optionsContainer.querySelectorAll('.js-option-radio');
      for (let i = 0; i < radioGroups.length; i++) {
        const optionRadios = radioGroups[i].querySelectorAll('input[type="radio"]');
        for (let j = 0; j < optionRadios.length; j++) {
          optionRadios[j].addEventListener('change', function () {
            addCartButtons.forEach((addCart) => {
              addCart.disabled = !isCheckedAllOption();
            });
          });
        }
      }
    }
  };

  const selectProduct = <HTMLLinkElement>document.querySelector('.js-select-product');
  const productId = selectProduct?.dataset?.id;
  const productCounterFooterButton = document.querySelector<HTMLLinkElement>('.p-counter-footer .js-select-product');
  if (productId && cart.hasSameProduct(parseInt(productId, 10))) {
    selectProduct.disabled = true;
    const cartIn = <HTMLElement>document.querySelector('.js-cart-in');
    if (cartIn) {
      cartIn.textContent = 'すでにカートに入っています';
    }
    if (productCounterFooterButton) {
      productCounterFooterButton.setAttribute('disabled', 'disabled');
      const footerCartIn = productCounterFooterButton.querySelector<HTMLElement>('.js-select-product-text');
      if (footerCartIn) {
        footerCartIn.textContent = 'すでにカートに入っています';
      }
    }
  }

  const initializeOptions = () => {
    const options = cart.getOrderOptions();
    if (optionsContainer) {
      // 既にオプションがカートにはいっていたらモーダルのラジオボタンを選択する
      const optionRadios = optionsContainer.querySelectorAll('input.option-radio[type="radio"]');
      const checkedMap: Record<string, boolean> = {};
      for (let i = 0; i < optionRadios.length; i++) {
        const radio = <HTMLInputElement>optionRadios[i];
        const checked = options.includes(radio.value);
        const radioName = radio.getAttribute('name') || '';
        checkedMap[radioName] = checkedMap[radioName] || checked;
        radio.checked = checked;
      }
      // 選択されていなかったら最初を選択する
      for (let i = 0; i < Object.keys(checkedMap).length; i++) {
        const name = Object.keys(checkedMap)[i];
        if (checkedMap[name]) {
          continue;
        }
        const radio = optionsContainer.querySelector<HTMLInputElement>(`input.option-radio[name="${name}"]`);
        if (!radio) {
          continue;
        }
        radio.checked = true;
      }

      addCartButtons.forEach((addCart) => {
        addCart.disabled = !isCheckedAllOption();
      });
    }
  };

  const addCartListener = () => {
    addCartButtons.forEach((addCart) => {
      addCart.addEventListener('click', function () {
        const sceneOptionIds: number[] = [];
        const classIds: number[] = [];
        let addOptionId = '';
        const optionIds: string[] = [];
        if (optionsContainer) {
          const classRadios = optionsContainer.querySelectorAll('input.product-class-radio[type="radio"]');
          for (let i = 0; i < classRadios.length; i++) {
            const radio = <HTMLInputElement>classRadios[i];
            if (radio.checked) {
              classIds.push(parseInt(radio.value, 10));
            }
          }
          const sceneOptionRadios = optionsContainer.querySelectorAll('input.scene-option-radio[type="radio"]');
          for (let i = 0; i < sceneOptionRadios.length; i++) {
            const radio = <HTMLInputElement>sceneOptionRadios[i];
            if (radio.checked) {
              sceneOptionIds.push(parseInt(radio.value, 10));
            }
          }

          const optionRadios = optionsContainer.querySelectorAll('input.option-radio[type="radio"]');
          for (let i = 0; i < optionRadios.length; i++) {
            const radio = <HTMLInputElement>optionRadios[i];
            if (radio.checked) {
              addOptionId = radio.value;
            }
            optionIds.push(radio.value);
          }

          if (addOptionId && optionIds.length > 0) {
            cart.replaceOrderOption(addOptionId, optionIds);
          }
          if (this.dataset.id && !cart.hasSameProduct(parseInt(this.dataset.id, 10))) {
            cart.add(parseInt(this.dataset.id, 10), classIds, sceneOptionIds);
          }
        } else {
          // 規格なしの商品用
          if (
            this.dataset.addType === 'product' &&
            this.dataset.id &&
            !cart.hasSameProduct(parseInt(this.dataset.id, 10))
          ) {
            cart.add(parseInt(this.dataset.id, 10), [], []);
          }
          // 規格なしのオプション用
          if (this.dataset.addType === 'option' && this.dataset.id) {
            cart.replaceOrderOption(this.dataset.id, []);
          }
        }

        if (this.dataset.cartUrl) {
          location.href = this.dataset.cartUrl;
        }
      });
    });
  };

  initializeOptions();
  addOptionsListener();
  addCartListener();

  // 追加注文用ロジック
  initializeAddOption();
  addAdditionalOptionsListener();
  addOptionListener();
}
