// Component
import './_tab.js';
import './_file';
import './_drawer.js';
import './_modal';
import './_accordion';
import './_header';
import './_check_admin_login';
import './_bullet';
import './_smooth-scroll';
import './_select_multi_project';
import './_link';

// Pages
import './_product';
import './_cart_confirm';
import './_cart_complete';
import './_cart';
import './_shopping';
import './_orders';
import './_additional_options';
import './_draft';
import './_enquete';
import './_delivery_confirm';
import './_voice';

// Vendor
import './_glide.js';
import './_flatpickr.js';
import './_barba.js';
import './_viewport-units-buggyfill.js';
import './_modernizr.js';
import './_lozad.js';
