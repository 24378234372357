type CartProduct = {
  key: string;
  product_id: number;
  class_ids: number[];
  option_ids: number[];
};

type Cart = {
  products: CartProduct[];
  options: string[];
  campaigns: string[];
  campaign_code: string;
};

export class CartStorage {
  static readonly CART_KEY: string = 'cart';
  static readonly ADMIN_CART_KEY: string = 'admin_cart';
  static readonly ADDITIONAL_OPTION_CART_KEY = 'additional_option_cart';
  storage: Storage;
  cartKey = CartStorage.CART_KEY;

  constructor() {
    this.storage = window.localStorage;
  }

  public add(productId: number, classIds: number[], optionIds: number[]): void {
    const cart = this.load();

    const time = new Date().getTime();
    // 重複チェック
    if (!cart.products.map((prd) => prd.product_id).includes(productId)) {
      cart.products.push({
        key: `${productId}_${time}`,
        product_id: productId,
        class_ids: classIds,
        option_ids: optionIds
      });
    }

    this.save(cart);
  }

  public getOrderOptions(): string[] {
    const cart = this.load();
    return cart.options;
  }

  public hasOrderOptions(): boolean {
    const cart = this.load();
    return cart.options.length > 0;
  }

  public replaceOrderOption(addOption: string, removeOptions: string[]): void {
    const cart = this.load();

    cart.options = cart.options.filter((value) => {
      return !removeOptions.includes(value);
    });

    // 重複チェック
    if (!cart.options.includes(addOption)) {
      cart.options.push(addOption);
    }
    this.save(cart);
  }

  public changeOrderOption(options: string[]): void {
    const cart = this.load();
    cart.options = options;

    this.save(cart);
  }

  public getCampaigns(): string[] {
    const cart = this.load();
    return cart.campaigns ? cart.campaigns : [];
  }

  public changeOrderCampaign(campaigns: string[]): void {
    const cart = this.load();
    cart.campaigns = campaigns;

    this.save(cart);
  }

  public getCampaignCode(): string {
    const cart = this.load();
    return cart.campaign_code ? cart.campaign_code : '';
  }

  public setCampaignCode(campaignCode: string): void {
    const cart = this.load();
    cart.campaign_code = campaignCode;

    this.save(cart);
  }

  public clearCampaignCode(): void {
    this.setCampaignCode('');
  }

  public clear(): void {
    this.storage.removeItem(this.cartKey);
  }

  public remove(key: string | undefined): number | null {
    if (!key) {
      return null;
    }

    const cart = this.load();

    let removeProductId: number | null = null;
    const products = cart.products;
    for (let i = 0; i < products.length; i++) {
      if (products[i].key === key) {
        removeProductId = products[i].product_id;
        cart.products.splice(i, 1);
        break;
      }
    }
    this.save(cart);

    return removeProductId;
  }

  public load(): Cart {
    const cart = this.storage.getItem(this.cartKey);
    if (cart !== null) {
      return JSON.parse(cart);
    }

    return { products: [], options: [], campaigns: [], campaign_code: '' };
  }

  public isShowCart(): boolean {
    const cart = this.load();
    return cart.products.length > 0 || cart.options.length > 0;
  }

  public hasProducts(): boolean {
    const cart = this.load();
    return cart.products.length > 0;
  }

  public hasSameProduct(productId: number): boolean {
    const cart = this.load();
    return cart.products.some((p) => p.product_id === productId);
  }

  public toQueryString(): string {
    const queryParams = {};

    const cart = this.load();
    if (cart.products) {
      for (let i = 0; i < cart.products.length; i++) {
        const product = cart.products[i];
        queryParams[`products[${i}][key]`] = product.key;
        queryParams[`products[${i}][id]`] = product.product_id;
        const optionIds = product.option_ids;
        for (let j = 0; j < optionIds.length; j++) {
          queryParams[`products[${i}][option][${j}]`] = optionIds[j];
        }
        const classIds = product.class_ids;
        for (let j = 0; j < classIds.length; j++) {
          queryParams[`products[${i}][class][${j}]`] = classIds[j];
        }
      }
    }

    if (cart.options) {
      for (let i = 0; i < cart.options.length; i++) {
        queryParams[`options[${i}]`] = cart.options[i];
      }
    }

    if (cart.campaigns) {
      for (let i = 0; i < cart.campaigns.length; i++) {
        queryParams[`campaigns[${i}]`] = cart.campaigns[i];
      }
    }

    if (cart.campaign_code) {
      queryParams[`campaign_code`] = cart.campaign_code;
    }

    if (Object.keys(queryParams).length > 0) {
      return `?${Object.entries<string>(queryParams)
        .map((entry: [string, string]): string => {
          return `${entry[0]}=${entry[1]}`;
        })
        .join('&')}`;
    }
    return '';
  }

  private save(cart: Cart): void {
    this.storage.setItem(this.cartKey, JSON.stringify(cart));
  }
}
