import InfiniteScroll from 'infinite-scroll';

document.addEventListener('DOMContentLoaded', function () {
  if (document.getElementById('c-voice-list') !== null) {
    new InfiniteScroll('#c-voice-list', {
      path: 'ul.pagination a[rel=next]',
      append: '.c-voice',
      button: '.view-more-scroll',
      hideNav: '.pagination',
      history: false,
      prefill: false,
      scrollThreshold: false
    });
  }
});
