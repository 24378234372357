if (document.querySelectorAll('.l-header__drawer').length > 0) {
  // ドロワーメニューをスクロール追従する
  window.addEventListener(
    'scroll',
    function () {
      let drawer_trigger = document.querySelector('.l-header__drawer');
      if (window.pageYOffset > 58) {
        drawer_trigger.classList.add('is-fixed');
      } else {
        drawer_trigger.classList.remove('is-fixed');
      }
    },
    false
  );

  // ドロワーメニュー開閉
  document.querySelector('.js-drawer--open').addEventListener(
    'click',
    function () {
      let window_offset_y = window.pageYOffset;
      let window_offset_y_px = window_offset_y * -1 + 'px';
      let body = document.querySelector('body');
      let drawer = document.querySelector('.l-drawer');
      let close = document.querySelector('.js-drawer--close');
      body.style.top = window_offset_y_px;
      body.classList.add('is-fixed');
      drawer.classList.add('is-active');
      close.addEventListener(
        'click',
        function () {
          body.classList.remove('is-fixed');
          body.removeAttribute('style');
          window.scrollTo(0, window_offset_y);
          drawer.classList.remove('is-active');
        },
        false
      );
    },
    false
  );
}
