import Toastify from 'toastify-js';

const options = {
  duration: 3000,
  close: true
};

// 表示位置を調整
window.onload = () => {
  const toastify = document.querySelector('.toastify-top');
  if (toastify !== null) {
    toastify.setAttribute('style', 'top:1.3rem');
  }

  const alertSuccess = document.querySelector('.alert-success');
  if (alertSuccess && alertSuccess.textContent) {
    modalAlert(alertSuccess.textContent);
  }

  const alertDanger = document.querySelector('.alert-danger');
  if (alertDanger && alertDanger.textContent) {
    modalAlert(alertDanger.textContent, true);
  }
};

export const modalAlert = (text: string, isDanger = false): void => {
  Toastify({
    ...options,
    text: text,
    className: isDanger ? 'is-danger' : 'is-success'
  }).showToast();
};
