import { modalAlert } from '../common/toastify';
import { fetch as fetchPolyfill } from 'whatwg-fetch';

document.addEventListener('DOMContentLoaded', () => {
  const isAdminCheck = async (): Promise<boolean> => {
    try {
      const response = await fetchPolyfill(`/top/is_admin`);
      const json = await response.json();
      return json.isAdmin;
    } catch {
      return false;
    }
  };

  const modalAdminAlert = async () => {
    const isAdmin = await isAdminCheck();
    if (isAdmin) {
      modalAlert('管理者としてログインしています。');
    }
  };

  modalAdminAlert();
});
